import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouterScrollBehavior,
} from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomePage.vue"),
  },
  {
    path: "/a-propos",
    name: "about",
    component: () => import("../views/AboutPage.vue"),
  },
  {
    path: "/equipe",
    name: "team",
    component: () => import("../views/TeamPage.vue"),
  },
  {
    path: "/fonctionnalites",
    name: "features",
    component: () => import("../views/FeaturesPage.vue"),
  },
  {
    path: "/offres-de-spectacles",
    name: "offers",
    component: () => import("../views/OffersPage.vue"),
  },
  {
    path: "/offres-de-spectacles/:show",
    name: "offer",
    props: true,
    component: () => import("../views/ShowOfferPage.vue"),
  },
];

const scrollBehavior: RouterScrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition;
  } else {
    return { left: 0, top: 0 };
  }
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
});

export default router;
