import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }

import { useRoute } from 'vue-router';
import TheFooterComponent from './components/TheFooterComponent.vue';
import TheHeaderComponent from './components/TheHeaderComponent.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {


const route = useRoute();


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(TheHeaderComponent, {
      class: _normalizeClass(`page-${String(_unref(route).name)}`)
    }, null, 8, ["class"]),
    _createVNode(_component_router_view),
    _createVNode(TheFooterComponent)
  ]))
}
}

})