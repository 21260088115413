import BootstrapVue from "bootstrap-vue-3";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import "bootstrap/dist/css/bootstrap.css";
import vueDebounce, { PluginConfig } from "vue-debounce";
import moment from "moment";
import "moment/locale/fr-ca";
//import VueMoment from "vue-moment";

const app = createApp(App);

app.use(BootstrapVue);
app.use<PluginConfig>(vueDebounce, {
  defaultTime: "500ms",
  listenTo: "keyup",
});

//app.use(VueMoment);

app.use(router);
app.mount("#app");
