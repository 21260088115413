<template>
  <footer class="Footer">
    <b-container fluid class="section">
      <b-row>
        <b-col cols="12" md="4">
          <p class="typo_footer-title">{{ translations.fr.contact.title }}</p>
          <p class="U--mb--0">{{ translations.fr.contact.name }}</p>
          <p v-html="translations.fr.contact.address" />
          <p class="U--mb--0" v-html="translations.fr.contact.tel" />
          <a class="email typo_base" :href="`mailto:${translations.fr.contact.email}`">{{ translations.fr.contact.email
            }}</a>
          <p class="U--mt--10 U--mb--20">{{ translations.fr.contact.opening }}</p>
          <div class="Social">
            <a :href="translations.fr.contact.socialUrl.facebook" alt="Facebook">
              <svg class="Social_logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
                <defs />
                <path fill="#FFF" fill-rule="nonzero"
                  d="M18 .992411v16.01116c0 .550447-.445982.992411-.992411.992411h-4.588393v-6.966964h2.338393l.349554-2.716072h-2.691964V6.577232c0-.7875.216964-1.321875 1.345982-1.321875h1.438393V2.824554c-.249108-.032143-1.100893-.108483-2.097322-.108483-2.073214 0-3.495536 1.265625-3.495536 3.591965v2.00491H7.260268v2.716072h2.346428V18H.992411C.445982 18 0 17.554018 0 17.007589V.992411C0 .445982.445982 0 .992411 0h16.01116C17.554018 0 18 .445982 18 .992411z" />
              </svg>
            </a>
          </div>
        </b-col>
        <b-col cols="12" md="4">
          <p class="typo_footer-title U--mb--12">{{ translations.fr.siteMap.title }}</p>
          <b-navbar-nav>
            <b-nav-item to="/">{{ navigation.fr.home }}</b-nav-item>
            <b-nav-item to="a-propos">{{ navigation.fr.about }}</b-nav-item>
            <b-nav-item to="equipe">{{ navigation.fr.team }}</b-nav-item>
            <b-nav-item to="fonctionnalites">{{ navigation.fr.features }}</b-nav-item>
            <b-nav-item to="offres-de-spectacles">{{ navigation.fr.offers }}</b-nav-item>
            <b-nav-item href="https://app.scenepro.ca/" target="_blank">{{ navigation.fr.connexion }}</b-nav-item>
          </b-navbar-nav>
        </b-col>
        <b-col cols="12" md="4">
          <p class="typo_footer-title">{{ translations.fr.newsletter.title }}</p>
          <b-input-group size="md">
            <b-form-input type="email" id="inputEmailInfolettre"
              :placeholder="translations.fr.newsletter.placeholder" />
            <b-input-group-append>
              <b-button size="sm" text="Button" @click="onClick">{{ translations.fr.newsletter.btnText }}</b-button>
            </b-input-group-append>
          </b-input-group>
          <form action="https://app.cyberimpact.com/optin" method="post" accept-charset="utf-8" style="display: none;"
            id="formInfolettre" ref="formInfolettre">
            <fieldset>
              <legend></legend>
              <div>
                <label for="ci_email">Courriel :</label>
                <input type="text" id="ci_email" name="ci_email" maxlength="255" />
              </div>
              <div style="display:block; visibility:hidden; height:1px;">
                <input style="display:none;" type="text" id="ci_verification" name="ci_verification" />
                <input type="hidden" id="ci_groups" name="ci_groups" value="20" />
                <input type="hidden" id="ci_account" name="ci_account" value="91f582af-62a4-420f-0596-377b527203fa" />
                <input type="hidden" id="ci_language" name="ci_language" value="fr_ca" />
                <input type="hidden" id="ci_sent_url" name="ci_sent_url" value="" />
                <input type="hidden" id="ci_error_url" name="ci_error_url" value="" />
                <input type="hidden" id="ci_confirm_url" name="ci_confirm_url" value="" />
              </div>
              <input type="submit" value="Envoyez" ref="formInfolettreSubmit" />

              <div style="color:#aaa; font-size:10px;"><a style="color:#aaa; font-size:10px;text-decoration:none;"
                  href="https://www.cyberimpact.com">Email marketing</a> <a
                  style="color:#aaa; font-size:10px;text-decoration:none;"
                  href="https://www.cyberimpact.com">Cyberimpact</a></div>
            </fieldset>
          </form>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script lang="ts" setup>
import translationsData from '@/dictionaries/footer.json';
import navigationData from '@/dictionaries/navigation.json';
import { ref } from 'vue';

const translations = ref(translationsData);
const navigation = ref(navigationData);

const onClick = () => {
  const ciEmail = document.getElementById('ci_email') as HTMLInputElement;
  const inputEmailInfolettre = document.getElementById(
    'inputEmailInfolettre'
  ) as HTMLInputElement;
  const formInfolettre = document.getElementById(
    'formInfolettre'
  ) as HTMLFormElement;

  ciEmail.value = inputEmailInfolettre.value;
  formInfolettre.submit();
};
</script>

<style lang="scss" scoped>
@import "../styles/variables";
@import "../styles/mixins";
@import "../styles/typographies";

.Footer {
  background-color: $sp-navy-blue;
  padding-top: 30px;
  padding-bottom: 30px;

  .section {
    padding: 0 15px !important;

    .row {
      margin: 0 -15px;
    }
  }

  @media ($bp-min-md) {
    padding-top: 75px;
    padding-bottom: 81px;
  }

  .typo_footer-title,
  p,
  ::v-deep(.nav-link) {
    color: $sp-white !important;
  }

  .typo_footer-title {
    margin-bottom: 12px;
  }

  ::v-deep(.nav-link) {
    @extend .typo_base;
    padding: 4px 0;
    display: table;
    position: relative;

    &:hover {
      text-decoration: none;

      &:before {
        opacity: 1;
        transition: opacity 0.2s ease-in-out;
      }
    }

    &:before {
      content: "";
      height: 1px;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 4px;
      opacity: 0;
      background-color: $sp-white;
      transition: opacity 0.2s ease-in-out;
    }
  }

  .form-control {
    @extend .typo_base;
    @include border-radius(4px);
    background-color: transparent;
    border: 2px solid rgba($sp-westar, 0.2);
    color: $sp-white;
    height: 38px;

    @media ($bp-min-lg) {
      height: 45px;
    }

    &:focus {
      border: 2px solid rgba($sp-westar, 0.4);
    }

    &::placeholder {
      color: rgba($sp-white, 0.4);
    }
  }

  .btn {
    @extend .typo_btn;
    @include border-radius(4px);
    background-color: $sp-sunset-orange;
    border: none;
    border-radius: 0 4px 4px 0;
    padding: 10px 15px;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    font-size: 14px;
    line-height: 18px;
    height: 38px;

    @media ($bp-min-lg) {
      padding: 10px 20px;
      height: 45px;
      font-size: 16px;
      line-height: 21px;
    }

    &:hover {
      opacity: 0.9;
      transition: opacity 0.2s ease-in-out;
    }
  }

  .col-12 {
    padding-top: 40px;
    padding-left: 15px;
    padding-right: 15px;

    &:first-child {
      padding-top: 0;
    }

    @media ($bp-min-md) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .Social {
    a {
      text-decoration: none;
      opacity: 1;
      transition: opacity 0.2s ease-in-out;

      &:hover {
        opacity: 0.5;
        transition: opacity 0.2s ease-in-out;
      }
    }

    &_logo {
      width: 18px;
      margin-right: 20px;
    }
  }
}
</style>