<template>
  <div id="app">
    <TheHeaderComponent :class="`page-${String(route.name)}`" />
    <router-view />
    <TheFooterComponent />
  </div>
</template>

<script lang="ts" setup>

import { useRoute } from 'vue-router';
import TheFooterComponent from './components/TheFooterComponent.vue';
import TheHeaderComponent from './components/TheHeaderComponent.vue';

const route = useRoute();

</script>

<style lang="scss">
@import './styles/main.scss';
</style>
