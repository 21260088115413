import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "Footer" }
const _hoisted_2 = { class: "typo_footer-title" }
const _hoisted_3 = { class: "U--mb--0" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["href"]
const _hoisted_7 = { class: "U--mt--10 U--mb--20" }
const _hoisted_8 = { class: "Social" }
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "typo_footer-title U--mb--12" }
const _hoisted_11 = { class: "typo_footer-title" }
const _hoisted_12 = {
  action: "https://app.cyberimpact.com/optin",
  method: "post",
  "accept-charset": "utf-8",
  style: {"display":"none"},
  id: "formInfolettre",
  ref: "formInfolettre"
}
const _hoisted_13 = {
  type: "submit",
  value: "Envoyez",
  ref: "formInfolettreSubmit"
}

import translationsData from '@/dictionaries/footer.json';
import navigationData from '@/dictionaries/navigation.json';
import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TheFooterComponent',
  setup(__props) {

const translations = ref(translationsData);
const navigation = ref(navigationData);

const onClick = () => {
  const ciEmail = document.getElementById('ci_email') as HTMLInputElement;
  const inputEmailInfolettre = document.getElementById(
    'inputEmailInfolettre'
  ) as HTMLInputElement;
  const formInfolettre = document.getElementById(
    'formInfolettre'
  ) as HTMLFormElement;

  ciEmail.value = inputEmailInfolettre.value;
  formInfolettre.submit();
};

return (_ctx: any,_cache: any) => {
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_nav_item = _resolveComponent("b-nav-item")!
  const _component_b_navbar_nav = _resolveComponent("b-navbar-nav")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_input_group_append = _resolveComponent("b-input-group-append")!
  const _component_b_input_group = _resolveComponent("b-input-group")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_container = _resolveComponent("b-container")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createVNode(_component_b_container, {
      fluid: "",
      class: "section"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_b_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_b_col, {
              cols: "12",
              md: "4"
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_2, _toDisplayString(translations.value.fr.contact.title), 1),
                _createElementVNode("p", _hoisted_3, _toDisplayString(translations.value.fr.contact.name), 1),
                _createElementVNode("p", {
                  innerHTML: translations.value.fr.contact.address
                }, null, 8, _hoisted_4),
                _createElementVNode("p", {
                  class: "U--mb--0",
                  innerHTML: translations.value.fr.contact.tel
                }, null, 8, _hoisted_5),
                _createElementVNode("a", {
                  class: "email typo_base",
                  href: `mailto:${translations.value.fr.contact.email}`
                }, _toDisplayString(translations.value.fr.contact.email), 9, _hoisted_6),
                _createElementVNode("p", _hoisted_7, _toDisplayString(translations.value.fr.contact.opening), 1),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("a", {
                    href: translations.value.fr.contact.socialUrl.facebook,
                    alt: "Facebook"
                  }, _cache[0] || (_cache[0] = [
                    _createElementVNode("svg", {
                      class: "Social_logo",
                      xmlns: "http://www.w3.org/2000/svg",
                      viewBox: "0 0 18 18"
                    }, [
                      _createElementVNode("defs"),
                      _createElementVNode("path", {
                        fill: "#FFF",
                        "fill-rule": "nonzero",
                        d: "M18 .992411v16.01116c0 .550447-.445982.992411-.992411.992411h-4.588393v-6.966964h2.338393l.349554-2.716072h-2.691964V6.577232c0-.7875.216964-1.321875 1.345982-1.321875h1.438393V2.824554c-.249108-.032143-1.100893-.108483-2.097322-.108483-2.073214 0-3.495536 1.265625-3.495536 3.591965v2.00491H7.260268v2.716072h2.346428V18H.992411C.445982 18 0 17.554018 0 17.007589V.992411C0 .445982.445982 0 .992411 0h16.01116C17.554018 0 18 .445982 18 .992411z"
                      })
                    ], -1)
                  ]), 8, _hoisted_9)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_b_col, {
              cols: "12",
              md: "4"
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_10, _toDisplayString(translations.value.fr.siteMap.title), 1),
                _createVNode(_component_b_navbar_nav, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_nav_item, { to: "/" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(navigation.value.fr.home), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_b_nav_item, { to: "a-propos" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(navigation.value.fr.about), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_b_nav_item, { to: "equipe" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(navigation.value.fr.team), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_b_nav_item, { to: "fonctionnalites" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(navigation.value.fr.features), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_b_nav_item, { to: "offres-de-spectacles" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(navigation.value.fr.offers), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_b_nav_item, {
                      href: "https://app.scenepro.ca/",
                      target: "_blank"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(navigation.value.fr.connexion), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_b_col, {
              cols: "12",
              md: "4"
            }, {
              default: _withCtx(() => [
                _createElementVNode("p", _hoisted_11, _toDisplayString(translations.value.fr.newsletter.title), 1),
                _createVNode(_component_b_input_group, { size: "md" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_form_input, {
                      type: "email",
                      id: "inputEmailInfolettre",
                      placeholder: translations.value.fr.newsletter.placeholder
                    }, null, 8, ["placeholder"]),
                    _createVNode(_component_b_input_group_append, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_button, {
                          size: "sm",
                          text: "Button",
                          onClick: onClick
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(translations.value.fr.newsletter.btnText), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("form", _hoisted_12, [
                  _createElementVNode("fieldset", null, [
                    _cache[1] || (_cache[1] = _createElementVNode("legend", null, null, -1)),
                    _cache[2] || (_cache[2] = _createElementVNode("div", null, [
                      _createElementVNode("label", { for: "ci_email" }, "Courriel :"),
                      _createElementVNode("input", {
                        type: "text",
                        id: "ci_email",
                        name: "ci_email",
                        maxlength: "255"
                      })
                    ], -1)),
                    _cache[3] || (_cache[3] = _createElementVNode("div", { style: {"display":"block","visibility":"hidden","height":"1px"} }, [
                      _createElementVNode("input", {
                        style: {"display":"none"},
                        type: "text",
                        id: "ci_verification",
                        name: "ci_verification"
                      }),
                      _createElementVNode("input", {
                        type: "hidden",
                        id: "ci_groups",
                        name: "ci_groups",
                        value: "20"
                      }),
                      _createElementVNode("input", {
                        type: "hidden",
                        id: "ci_account",
                        name: "ci_account",
                        value: "91f582af-62a4-420f-0596-377b527203fa"
                      }),
                      _createElementVNode("input", {
                        type: "hidden",
                        id: "ci_language",
                        name: "ci_language",
                        value: "fr_ca"
                      }),
                      _createElementVNode("input", {
                        type: "hidden",
                        id: "ci_sent_url",
                        name: "ci_sent_url",
                        value: ""
                      }),
                      _createElementVNode("input", {
                        type: "hidden",
                        id: "ci_error_url",
                        name: "ci_error_url",
                        value: ""
                      }),
                      _createElementVNode("input", {
                        type: "hidden",
                        id: "ci_confirm_url",
                        name: "ci_confirm_url",
                        value: ""
                      })
                    ], -1)),
                    _createElementVNode("input", _hoisted_13, null, 512),
                    _cache[4] || (_cache[4] = _createElementVNode("div", { style: {"color":"#aaa","font-size":"10px"} }, [
                      _createElementVNode("a", {
                        style: {"color":"#aaa","font-size":"10px","text-decoration":"none"},
                        href: "https://www.cyberimpact.com"
                      }, "Email marketing"),
                      _createTextVNode(),
                      _createElementVNode("a", {
                        style: {"color":"#aaa","font-size":"10px","text-decoration":"none"},
                        href: "https://www.cyberimpact.com"
                      }, "Cyberimpact")
                    ], -1))
                  ])
                ], 512)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})